import * as React from "react";
import Layout from "../components/layout/MainLayout";
import EquipmentList from "../components/ui/Equipment/EquipmentList";
import get from "lodash/get";
import GradientAngleHeader from "../components/layout/GradientAngleHeader";
import { useState } from "react";

const CategoryNode = ({ pageContext }) => {
  const [innerHeight, setInnerHeight] = useState();
  const slug = get(pageContext, `slug`, "all");
  return (
    <Layout>
      {/* <WmkSeo.Meta
        title={get(page, `title`)}
        siteTitle={get(data, `site.meta.title`)}
        baseUrl={get(data, `site.meta.baseUrl`)}
        path={path === "/" ? "" : path}
        slug={get(page, `slug`)}
        description={get(data, `site.meta.description`)}
        twitterHandle={get(data, `site.meta.twitterHandle`)}
        ogImage={get(page, `ogImage`)}
        twitterImage={get(page, `twitterImage`)}
      /> */}
      <GradientAngleHeader
        height={innerHeight}
        setter={setInnerHeight}
        white
        title={get(pageContext, `title`)}
       // text={get(page, `header.text`)}
      />
      <EquipmentList currentCat={slug} />
    </Layout>
  );
};

export default CategoryNode;
